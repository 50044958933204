import React from 'react'
import { FormModalPage } from '@commercetools-frontend/application-components'
import { useHistory, useParams } from 'react-router-dom'
import { Category } from '@commercetools/platform-sdk'
import LoadingSpinner from '@commercetools-uikit/loading-spinner'
import { useShowNotification } from '@commercetools-frontend/actions-global'
import { DOMAINS } from '@commercetools-frontend/constants'
import { ProductRange } from '@moonpig/studio-product-personalisation-types'
import { useApplicationUrl } from '../../hooks/useApplicationUrl'
import { useCategory } from '../../hooks/connectors/use-category-connector'
import { DEFAULT_LOCALE } from '../../constants'
import { useProductRangeDetailForm } from './hooks/use-product-range-detail-form'
import { ProductRangeDetailForm } from './components/product-range-detail-form'
import { useProductRangeFetcher } from '../../hooks/connectors/use-product-ranges-connector'
import { useApplicationUser } from '../../hooks/useApplicationUser'

type ProductRangesDetailsProps = {
  category: Category
  range: Omit<ProductRange, 'created' | 'updated'>
}

const ProductRangesDetails: React.FC<ProductRangesDetailsProps> = props => {
  const history = useHistory()
  const { url } = useApplicationUrl()
  const { user } = useApplicationUser()
  const showNotification = useShowNotification()

  const handleSubmitted = (id: string) => {
    showNotification({
      kind: 'success',
      domain: DOMAINS.SIDE,
      text: `Product Range has been updated successfully!`,
    })

    history.push(url(`product-ranges/${id}`))
  }

  const handleFailed = () => {
    showNotification({
      kind: 'error',
      domain: DOMAINS.SIDE,
      text: `Product Range failed to be updated.`,
    })
  }

  const form = useProductRangeDetailForm({
    values: {
      id: props.category.id,
      key: props.category.key ?? '',
      profanityFilter: props.range.profanityFilter ? 'enabled' : 'disabled',
      stickerSets: props.range.stickerSets,
      actor: user?.email as string,
    },
    onSubmitted: (id: string) => handleSubmitted(id),
    onFailed: () => handleFailed(),
  })

  return (
    <FormModalPage
      isOpen={true}
      title={`Update ${props.category.name[DEFAULT_LOCALE]}`}
      subtitle={'Manage the attributes for the product range'}
      isPrimaryButtonDisabled={form.formik.isSubmitting || !form.formik.dirty}
      isSecondaryButtonDisabled={!form.formik.dirty}
      onSecondaryButtonClick={() => form.formik.resetForm()}
      onPrimaryButtonClick={() => form.formik.handleSubmit()}
      labelPrimaryButton="Save Product Range"
      labelSecondaryButton="Revert Changes"
      onClose={() => history.push(url('product-ranges'))}
    >
      <ProductRangeDetailForm formik={form.formik} />
    </FormModalPage>
  )
}

export const ProductRangesDetailsContainer = () => {
  const params = useParams<{ id: string }>()

  const { data: categoryData, loading: categoryLoading } = useCategory({
    id: params.id,
  })

  const { data: rangeData, loading: rangeLoading } = useProductRangeFetcher({
    id: params.id,
  })

  const isLoading = categoryLoading || rangeLoading

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      {categoryData && rangeData ? (
        <ProductRangesDetails range={rangeData} category={categoryData} />
      ) : null}
    </>
  )
}
