import { FormikHelpers, useFormik } from 'formik'
import { useCallback } from 'react'
import { useUpdateProductRangeDetails } from '../../../hooks/connectors/use-product-ranges-connector'

type ValidationError = {
  name?: { missing?: boolean }
  productType?: { missing?: boolean }
  scenes?: { missing?: boolean }
}

type ProductRangeDetailFormValues = {
  id: string
  key: string
  stickerSets: string[]
  profanityFilter: 'enabled' | 'disabled'
  actor: string
}

export type ProductRangeDetailForm = ReturnType<
  typeof useFormik<ProductRangeDetailFormValues>
>

const validate = () => {
  const errors: ValidationError = {}

  return errors
}

const toRequestPayload = (values: ProductRangeDetailFormValues) => {
  return {
    id: values.id,
    key: values.key,
    stickerSets: values.stickerSets,
    profanityFilter: values.profanityFilter === 'enabled',
    actor: values.actor,
  }
}

type ProductRangeDetailFormProps = {
  values: ProductRangeDetailFormValues
  onSubmitted: (id: string) => void
  onFailed: () => void
}

export const useProductRangeDetailForm = (
  props: ProductRangeDetailFormProps,
) => {
  const updateProductRangeDetails = useUpdateProductRangeDetails()

  const handleSubmit = useCallback(
    async (
      formikValues: ProductRangeDetailFormValues,
      formikHelpers: FormikHelpers<ProductRangeDetailFormValues>,
    ) => {
      try {
        const response = await updateProductRangeDetails.action(
          toRequestPayload(formikValues),
        )

        if (response.type === 'success') {
          props.onSubmitted(formikValues.id)
        } else if (response.type === 'validation') {
          const errors = response.messages.reduce((acc, current) => {
            acc[current.field] = { [current.reason]: true }
            return acc
          }, {} as Record<string, Record<string, boolean>>)

          formikHelpers.setErrors(errors)

          props.onFailed()
        } else {
          props.onFailed()
        }
      } catch (e) {
        props.onFailed()
      }
    },
    [],
  )

  const formik = useFormik<ProductRangeDetailFormValues>({
    initialValues: props.values,
    validate,
    onSubmit: (values, helpers) => handleSubmit(values, helpers),
    enableReinitialize: true,
  })

  return { formik }
}
