import {
  ProductImageSceneCollection,
  ValidationError,
} from '@moonpig/studio-product-personalisation-types'
import { PublishedTemplate } from '@moonpig/moonpig-studio-templates'

export type InvalidMappingResult = {
  errors: ValidationError[]
}

export const isInvalidMappingResult = (
  response: object,
): response is InvalidMappingResult => {
  return !!response && 'errors' in response
}

export type DuplicateKeyResult = {
  message: string
  keys: string[]
}

export const isDuplicateKeyResult = (
  response: object,
): response is DuplicateKeyResult => {
  return (
    !!response &&
    'message' in response &&
    response.message === 'Duplicate Product Key' &&
    'keys' in response
  )
}

export type SuccessResponse = Record<string, never>

export type SearchApiResponseResult = {
  index: number
  directSmileSet: string | undefined
  id: string
  publishDate: string
  templateId: string
  templateData: PublishedTemplate
  templateSearchName: string
}

export type MappingEntry = {
  templateId: string
  index: number
  templateData: PublishedTemplate | undefined
  publishDate: string | undefined
  copies: number | undefined
}

export interface MappingApiResponse {
  productId: string
  stickerSet: string[]
  profanityFilter: boolean
  mapping: MappingEntry[]
  pdfUrl?: string
  renderedProofUrl?: string
  created?: {
    by: string
    at: string
  }
  updated?: {
    by: string
    at: string
  }
  productImageSceneCollectionId?: string
  actions: {
    canDownloadPDF: boolean
    canCreateTestProof: boolean
    canDuplicateProduct: boolean
    canGoToProduct: boolean
  }
}

export const isMappingResponse = (
  response: object,
): response is MappingApiResponse => {
  return !!response && 'productId' in response
}

export type SearchApiResponse = {
  results: SearchApiResponseResult[]
}

export const isSearchResult = (
  response: object,
): response is SearchApiResponse => {
  return !!response && 'results' in response
}

export const isProductImageSceneCollectionsResult = (
  response: unknown,
): response is ProductImageSceneCollection[] => {
  return (
    response instanceof Array &&
    (response.length === 0 || response[0].productImageSceneIds)
  )
}

export interface MappingError {
  message: string
  index?: number | undefined
}

export type ProductTypeSettings = {
  canHaveMultipleCopies: boolean
}
