import {
  useAsyncDispatch,
  actions,
  TSdkActionPost,
  TSdkActionGet,
} from '@commercetools-frontend/sdk'
import { useRef, useState, useEffect } from 'react'
import { ProductRange } from '@moonpig/studio-product-personalisation-types'
import { MAPPING_API_HOST } from '../../constants'
import { getProjectKey } from '../../services'
import { HttpErrorType } from '../../types/dispatch'

type ActionProductRangeDetailsSuccess = {
  type: 'success'
  imageSceneCollectionId: string
}

type ActionProductRangeDetailsValidation = {
  type: 'validation'
  messages: Array<{ field: string; reason: string }>
}

type ActionProductRangeDetailsError = {
  type: 'error'
}

type UpdateProductRangeDetailsResult =
  | ActionProductRangeDetailsSuccess
  | ActionProductRangeDetailsValidation
  | ActionProductRangeDetailsError

export type UpdateProductRangeDetailInput = {
  id: string
  stickerSets: string[]
  profanityFilter: boolean
  actor: string
}

export const useUpdateProductRangeDetails = () => {
  const dispatch = useAsyncDispatch<
    TSdkActionPost,
    UpdateProductRangeDetailsResult
  >()

  const action = async (payload: UpdateProductRangeDetailInput) => {
    try {
      return await dispatch(
        actions.post({
          uri: '/proxy/forward-to',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'X-Forward-To': `${MAPPING_API_HOST}/product-ranges/${payload.id}`,
            'X-Project-Key': getProjectKey(),
            'Accept-version': 'v2',
          },
          payload,
        }),
      )
    } catch (e: unknown) {
      if ((e as HttpErrorType).name === 'HttpError') {
        return (e as HttpErrorType).body as
          | ActionProductRangeDetailsValidation
          | ActionProductRangeDetailsError
      }

      throw e
    }
  }

  return { action }
}

type ProductRangeFetcherProps = {
  id: string
}

type ProductRangeFetcherSuccess = {
  type: 'success'
  productRange: ProductRange
}

type ProductRangeFetcherError = {
  type: 'error'
  reason: string
}

type ProductRangeFetcherResult =
  | ProductRangeFetcherSuccess
  | ProductRangeFetcherError

export const useProductRangeFetcher = (props: ProductRangeFetcherProps) => {
  const cancelled = useRef<boolean>(false)

  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<Omit<ProductRange, 'created' | 'updated'>>()

  const dispatch = useAsyncDispatch<TSdkActionGet, ProductRangeFetcherResult>()

  useEffect(() => {
    cancelled.current = false

    const action = async () => {
      if (!cancelled.current) {
        setLoading(true)
      }

      if (props.id === null) {
        setData(undefined)
        setLoading(false)
        return
      }

      try {
        const response = await dispatch(
          actions.get({
            uri: '/proxy/forward-to',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'X-Forward-To': `${MAPPING_API_HOST}/product-ranges/${props.id}`,
              'X-Project-Key': getProjectKey(),
              'Accept-version': 'v2',
            },
          }),
        )
        if (!cancelled.current) {
          setLoading(false)

          if (response.type === 'success') {
            setData(response.productRange)
            setError(undefined)
          }
        }
      } catch (e: unknown) {
        if (!cancelled.current) {
          setLoading(false)

          if ((e as HttpErrorType).statusCode === 404) {
            if (
              ((e as HttpErrorType).body as ProductRangeFetcherError).reason ===
              'ResourceNotFound'
            ) {
              setData({
                categoryId: '',
                categoryKey: '',
                profanityFilter: false,
                stickerSets: [],
              })
              setError(undefined)

              return
            }
          }

          setData(undefined)
          setError(
            ((e as HttpErrorType).body as ProductRangeFetcherError).reason,
          )
        }
      }
    }

    action()

    return () => {
      cancelled.current = true
    }
  }, [dispatch, props.id])

  return { data, error, loading }
}
