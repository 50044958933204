import {
  TSdkActionGet,
  actions,
  useAsyncDispatch,
} from '@commercetools-frontend/sdk'
import { ProductMapping } from '@moonpig/studio-product-personalisation-types'
import { useEffect, useRef, useState } from 'react'
import { MAPPING_API_HOST } from '../../../constants'
import { getProjectKey } from '../../../services'
import {
  isInvalidMappingResult,
  isDuplicateKeyResult,
  isMappingResponse,
  MappingApiResponse,
  SuccessResponse,
} from '../../../types'
import { useApplicationUser } from '../../../hooks/useApplicationUser'

type Json = { [key: string]: unknown }
type HttpErrorType = {
  code: number
  body?: Json
}

type KeyUsageResponse = {
  isDuplicate: boolean
  usages: string[]
}

const isKeyUsage = (response: unknown): response is KeyUsageResponse => {
  const props = Object.getOwnPropertyNames(response)
  return props.includes('isDuplicate') && props.includes('usages')
}

const isHttpError = (error: unknown): error is HttpErrorType => {
  const props = Object.getOwnPropertyNames(error)
  return props.includes('code') && props.includes('body')
}

export const useSaveProductMapping = () => {
  const { user } = useApplicationUser()
  const dispatch = useAsyncDispatch()

  return async (mapping: ProductMapping) => {
    const auditEntry = {
      by: user?.email,
      at: new Date().toISOString(),
    }
    const postRequest = actions.post({
      uri: '/proxy/forward-to',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-Forward-To': `${MAPPING_API_HOST}/save`,
        'X-Project-Key': getProjectKey(),
        'Accept-version': 'v2',
      },
      payload: {
        created: auditEntry,
        ...mapping,
        updated: auditEntry,
      },
    })

    try {
      await dispatch(postRequest)
      return {} as SuccessResponse
    } catch (err: unknown) {
      if (isHttpError(err) && err.body) {
        if (isInvalidMappingResult(err.body)) {
          return err.body
        }

        if (err.code === 409 && isDuplicateKeyResult(err.body)) {
          return err.body
        }
      }

      throw err
    }
  }
}

export const defaultEmptyApiResponse = (
  productId: string,
): MappingApiResponse => ({
  productId,
  stickerSet: ['none'],
  profanityFilter: false,
  mapping: [],
  actions: {
    canDownloadPDF: false,
    canCreateTestProof: false,
    canDuplicateProduct: false,
    canGoToProduct: false,
  },
})

export const useProductMappings = (
  productKey: string,
  defaultResponse: Partial<MappingApiResponse>,
) => {
  const cancelled = useRef<boolean>(false)

  const [productMapping, setProductMapping] = useState<MappingApiResponse>({
    ...defaultEmptyApiResponse(productKey),
    ...defaultResponse,
  })
  const [keyUsage, setKeyUsage] = useState<KeyUsageResponse>()

  const [isFetching, setIsFetching] = useState<boolean>(false)
  const dispatch = useAsyncDispatch<TSdkActionGet, MappingApiResponse>()
  const createProductMapping = useSaveProductMapping()

  const getMapping = async (afterSave = false) => {
    const getRequest = actions.get({
      uri: '/proxy/forward-to',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-Forward-To': `${MAPPING_API_HOST}/mapping/${productKey}`,
        'X-Project-Key': getProjectKey(),
        'Accept-version': 'v2',
      },
    })

    try {
      if (!productKey) return
      const result = await dispatch(getRequest)
      if (isMappingResponse(result)) {
        if (afterSave) {
          setProductMapping({
            ...result,
            pdfUrl: undefined,
          })
        } else {
          setProductMapping(result)
        }
      }
    } catch (err: unknown) {
      if (isHttpError(err) && err.code !== 404) {
        throw err
      }

      if (!cancelled.current) {
        setProductMapping({
          ...defaultEmptyApiResponse(productKey),
          ...defaultResponse,
        })
      }
    }
  }

  const getKeyUsage = async () => {
    const getRequest = actions.get({
      uri: '/proxy/forward-to',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-Forward-To': `${MAPPING_API_HOST}/mapping/${productKey}/duplicate-key-check`,
        'X-Project-Key': getProjectKey(),
        'Accept-version': 'v2',
      },
    })

    const data = await dispatch(getRequest)
    if (isKeyUsage(data)) {
      if (!cancelled.current) {
        setKeyUsage(data)
      }
    }
  }

  useEffect(() => {
    cancelled.current = false

    setIsFetching(true)
    Promise.all([getMapping(), getKeyUsage()]).finally(() => {
      if (!cancelled.current) {
        setIsFetching(false)
      }
    })

    return () => {
      cancelled.current = true
    }
  }, [productKey])

  const saveAndReload = async (mapping: ProductMapping) => {
    const result = await createProductMapping(mapping)
    if (!isInvalidMappingResult(result)) {
      await getMapping(true)
    }
    return result
  }

  return {
    isFetching,
    productMapping,
    setProductMapping,
    saveProductMapping: async (mapping: ProductMapping) =>
      saveAndReload(mapping),
    keyUsage,
  }
}
